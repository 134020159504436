import { Routes, Route } from "react-router-dom";

import Welcome from "../pages/Welcome";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";

function AuthRoute() {
  return (
    <Routes>
      <Route path="/" exact element={<Welcome />} />
      <Route path="/login" element={<SignIn />} />
      <Route path="/register" element={<SignUp />} />
    </Routes>
  );
}

export default AuthRoute;
