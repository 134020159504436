import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BiUserCircle, BiDownArrow, BiLogInCircle } from "react-icons/bi";

import useAuth from "../../hooks/auth";

import logo from "../../assets/logo.png";

import { Container, Content, Profile, Dropdown, DropdownButton, ButtonLogin } from "./styles";

export default function Header() {
  const token = localStorage.getItem("@ai-pt: token");

  const { signOut } = useAuth();

  const [visibleDropDown, setVisibleDropDown] = useState(false);

  const navigate = useNavigate();

  function handleSignOut() {
    signOut();
    navigate("/login");
  }

  return (
    <Container>
      <Content>
        <nav>
          {token ? (
            <Link to="/home">
              <img src={logo} alt="AI" />
            </Link>
          ) : (
            <Link to="/login">
              <img src={logo} alt="AI" />
            </Link>
          )}
        </nav>

        {token ? (
          <aside onPointerMove={() => setVisibleDropDown(true)}>
            <Profile onClick={() => setVisibleDropDown(true)}>
              <BiUserCircle size={30} />
              <BiDownArrow size={15} />
            </Profile>

            {visibleDropDown && (
              <Dropdown
                onMouseEnter={() => setVisibleDropDown(true)}
                onMouseLeave={() => setVisibleDropDown(false)}
              >
                <DropdownButton>Settings</DropdownButton>
                <DropdownButton onClick={() => handleSignOut()}>Sair</DropdownButton>
              </Dropdown>
            )}
          </aside>
        ) : (
          <ButtonLogin onClick={() => navigate("/login")}>
            <BiLogInCircle />
            ENTRAR
          </ButtonLogin>
        )}
      </Content>
    </Container>
  );
}
