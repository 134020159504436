import React from "react";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";

import Routes from "./routes";
import history from "./history";

import { AuthProvider } from "./context/AuthContext";

import GlobalStyle from "./styles/global";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter history={history}>
        <ChakraProvider>
          <Routes />
        </ChakraProvider>
        <GlobalStyle />
        <ToastContainer autoClose={3000} />
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
