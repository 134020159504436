import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  border: 1px solid ${(props) => (props.error ? "red" : "#cfcfcf")};
  border-radius: 6px;
  align-items: center;
  svg {
    margin: 0;
    color: #777;
  }
`;

export const ReceiveInput = styled.input`
  height: 56px;
  width: 100%;
  padding: 12px;
  background-color: transparent;
  border: 0;
`;
