import React from "react";
import { Formik } from "formik";
import { AiOutlineMail } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import { object, string } from "yup";
import { useNavigate } from "react-router-dom";

import {
  Container,
  Card,
  CardHeader,
  CardTitle,
  CardSubTitle,
  Form,
  ContainerInput,
  Button,
  ButtonLink,
  BoxButton,
  InfoBox,
  BoxCard,
} from "./styles";

import Input from "../../components/Input";
import Header from "../../components/Header";

import useAuth from "../../hooks/auth";

const yupValidation = object({
  email: string().email("Email inválido.").required("Por-favor informe um email"),
  password: string().required("Por-favor informe uma senha").min(6),
});

export default function SignIn() {
  const { signIn, loading } = useAuth();
  const navigate = useNavigate();

  const handleSignIn = async (values) => {
    const { email, password } = values;
    signIn(email, password);
  };

  return (
    <Container>
      <Header />
      <BoxCard>
        <Card>
          <InfoBox>
            <CardHeader>
              <CardTitle>Faça o login</CardTitle>
              <CardSubTitle>para acessar gratuitamente o teste de nivelamento.</CardSubTitle>
            </CardHeader>

            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={yupValidation}
              onSubmit={(values, { setSubmitting }) => {
                handleSignIn(values);
                setSubmitting(false);
              }}
            >
              {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
                <Form>
                  <ContainerInput>
                    <Input
                      icon={AiOutlineMail}
                      placeholder="Email"
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      error={errors.email}
                    />
                    <Input
                      icon={RiLockPasswordLine}
                      placeholder="Senha"
                      type="password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      error={errors.password}
                    />
                  </ContainerInput>
                  <BoxButton>
                    <Button onClick={handleSubmit}>{loading ? "Loading..." : "Entrar"}</Button>
                    <ButtonLink onClick={() => navigate("/register")}>Não tenho conta</ButtonLink>
                  </BoxButton>
                </Form>
              )}
            </Formik>
          </InfoBox>
        </Card>
      </BoxCard>
    </Container>
  );
}
