import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Rings } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

import Header from "../../components/Header";
// import ImgQuestion from "../../components/ImgQuestions";

import api from "../../services/api";

import {
  Container,
  Card,
  CardHeader,
  HeaderTitle,
  Content,
  AnswerWrapper,
  Button,
  ButtonText,
  AnswerCheckInput,
  StyledLabel,
} from "./styles";

export default function Questions() {
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [question, setQuestion] = useState("");
  const [testAnswer, setTestAnswer] = useState(false);
  const [managerAnswers, setManagerAnswers] = useState([
    {
      alternative: 0,
      checked: false,
    },
    {
      alternative: 1,
      checked: false,
    },
    {
      alternative: 2,
      checked: false,
    },
    {
      alternative: 3,
      checked: false,
    },
  ]);

  const navigate = useNavigate();

  const loadQuestions = async () => {
    try {
      setLoading(true);
      const { data } = await api.get("/questions");
      setQuestions(data);
      setQuestion(data[0]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadQuestions();
  }, []);

  const handleSubmit = async () => {
    try {
      const token = await localStorage.getItem("@ai-pt: token");
      if (token) {
        api.defaults.headers.Authorization = `Bearer ${token}`;
      }
      const { data } = await api.post("/testfinish", { answers: testAnswer });
      console.log(data);
    } catch (error) {
      console.log(`error handleSubmit --> ${error}`);
    }
  };

  useEffect(() => {
    if (count < 10) {
      return setQuestion(questions[count]);
    }

    function callHandleAndNavigate() {
      handleSubmit();
      navigate("/finished");
    }

    return callHandleAndNavigate();
  }, [count]);

  const handleAnswer = () => {
    const checkAnswer = managerAnswers.findIndex((element) => element.checked === true);
    if (checkAnswer < 0) {
      return toast.error("Por-favor, selecione uma alternativa.");
    }

    const cleanAnswers = [
      {
        alternative: 0,
        checked: false,
      },
      {
        alternative: 1,
        checked: false,
      },
      {
        alternative: 2,
        checked: false,
      },
      {
        alternative: 3,
        checked: false,
      },
    ];
    setManagerAnswers(cleanAnswers);
    const testAnswerCopy = Array.from(testAnswer);
    testAnswerCopy.push({
      alternative: count + 1,
      answerId: managerAnswers[checkAnswer].answerId,
    });
    setTestAnswer(testAnswerCopy);

    return setCount(count + 1);
  };

  const handleCheck = (answer, index) => {
    const answersCopy = Array.from(managerAnswers);

    answersCopy[index] = {
      alternative: index,
      checked: !managerAnswers[index].checked,
      answerId: answer.id,
    };

    const answersDontSelected = answersCopy.filter((element) => element.alternative !== index);

    answersDontSelected.map((element) => {
      const answerIndex = answersCopy.findIndex((e) => e.alternative === element.alternative);
      answersCopy[answerIndex].checked = false;
      answersCopy[answerIndex].answerId = "";
      return answerIndex;
    });

    setManagerAnswers(answersCopy);
  };

  return (
    <Container>
      <Header />
      {loading ? (
        <Rings
          height="150"
          width="150"
          color="rgba(35, 124, 233, 10)"
          radius="6"
          wrapperStyle={{ marginTop: 250 }}
          visible
          ariaLabel="rings-loading"
        />
      ) : (
        question && (
          <Card>
            <CardHeader>
              <HeaderTitle>{`${count + 1}. ${question.title}`} </HeaderTitle>
            </CardHeader>

            <Content>
              {/* <ImgQuestion receber={count} /> */}

              {question.answers.map((answer, index) => (
                <AnswerWrapper>
                  <AnswerCheckInput
                    type="checkbox"
                    id={answer.id}
                    key={answer.id}
                    name="scales"
                    checked={managerAnswers[index].checked}
                    value={answer.id}
                    onChange={() => handleCheck(answer, index)}
                  />
                  <StyledLabel htmlFor="scales">{answer.title}</StyledLabel>
                </AnswerWrapper>
              ))}
            </Content>

            <Button onClick={() => handleAnswer()}>
              <ButtonText>Next (próxima)</ButtonText>
            </Button>
          </Card>
        )
      )}
    </Container>
  );
}
