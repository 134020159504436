import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
} from "@chakra-ui/react";

import { TextWrapper, StyledText } from "./styles";

export default function TestResultModal({ visibleModal, setVisibleModal, testResult }) {
  return (
    <Modal isOpen={visibleModal} onClose={() => setVisibleModal(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>My Test Result</ModalHeader>
        <ModalCloseButton />

        {testResult && (
          <ModalBody>
            <TextWrapper>
              <StyledText>Perguntas:</StyledText>
              <Text>10</Text>
            </TextWrapper>
            <TextWrapper>
              <StyledText>Respostas Corretas:</StyledText>
              <Text>{testResult.correct}</Text>
            </TextWrapper>
            <TextWrapper>
              <StyledText>Respostas Incorretas:</StyledText>
              <Text>{testResult.incorrect}</Text>
            </TextWrapper>
            <TextWrapper>
              <StyledText>Resultado:</StyledText>
              <Text>{testResult.grade}</Text>
            </TextWrapper>
            <TextWrapper>
              <StyledText>Data:</StyledText>
              <Text>{testResult.created_at}</Text>
            </TextWrapper>
          </ModalBody>
        )}

        <ModalFooter>
          {/* <Button colorScheme="blue" mr={3} onClick={() => setVisibleModal(false)}>
            Close
          </Button>
          <Button variant="ghost">Secondary Action</Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
