/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
// import { isBefore } from "date-fns";

// import history from "../history";
// import { useNavigate } from "react-router-dom";

import api from "../services/api";

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [signed, setSigned] = useState(false);
  const [authData, setAuthData] = useState({ token: "" });
  const [loading, setLoading] = useState(false);

  const signIn = async (email, password) => {
    // const navigate = useNavigate();
    try {
      setLoading(true);

      const { data } = await api.post("/sessions", {
        email,
        password,
      });

      const { accessToken } = data;

      const user = { name: "test" };

      await localStorage.setItem("@ai-pt: user", JSON.stringify(user));
      await localStorage.setItem("@ai-pt: token", accessToken);

      const authenticatedUser = {
        token: accessToken,
        user,
      };

      api.defaults.headers.authorization = `Bearer ${accessToken}`;

      setAuthData(authenticatedUser);
      setLoading(false);
      setSigned(true);
      // navigate("/home");
      toast.success("Sucesso!");
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Error!");
    }
  };

  const signOut = async () => {
    await localStorage.removeItem("@ai-pt: token");
    await localStorage.removeItem("@ai-pt: user");
    api.defaults.headers.Authorization = null;
    setAuthData({ token: "" });
    setSigned(false);
  };

  const setUserData = async (data) => {
    setAuthData(data);
  };

  const checkAuthData = async () => {
    const token = await localStorage.getItem("@ai-pt: token");
    // const user = await localStorage.getItem("@ai-pt: user");
    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
    }
    // if (user && token) {
    //   if (isBefore(JSON.parse(user).expiresAt)) {
    //     signOut();
    //   }

    //   const parsedUser = JSON.parse(user);

    //   setAuthData({ token, user: parsedUser });

    //   api.defaults.headers.Authorization = `Bearer ${token}`;
    // } else {
    //   signOut();
    // }
  };

  useEffect(() => {
    checkAuthData();
  }, []);

  return (
    <AuthContext.Provider value={{ signIn, signOut, user: authData, setUserData, loading, signed }}>
      {children}
    </AuthContext.Provider>
  );
}
