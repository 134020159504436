import { Routes, Route } from "react-router-dom";

import Home from "../pages/Home";
import Questions from "../pages/Questions";
import FinishedTest from "../pages/FinishedTest";

function AuthRoute() {
  return (
    <Routes>
      <Route path="/home" element={<Home />} />
      <Route path="/questions" element={<Questions />} />
      <Route path="/finished" element={<FinishedTest />} />
    </Routes>
  );
}

export default AuthRoute;
