import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background: #f0f0f0;
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  max-width: 100vw;
  width: 800px;
  height: 200px;
  -webkit-box-shadow: -1px 3px 15px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 3px 15px -7px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 3px 15px -7px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
  margin-top: 50px;

  @media (max-width: 900px) {
    width: 95%;
  }

  @media (max-width: 560px) {
    flex-direction: column;
    height: 300px;
    width: 95%;
  }
`;

export const CardLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 25px;
`;

export const Image = styled.img`
  width: 150px;
  border-radius: 8px;
`;

export const TextWrapepr = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-left: 25px;
`;

export const CardTitle = styled.h1`
  color: #4c4c4c;
  font-family: din-round, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 25px;
`;

export const CardSubTitle = styled.p`
  color: #4c4c4c;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  margin-top: 8px;
`;

export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Button = styled.button`
  width: 200px;
  height: 50px;
  background: ${(props) =>
    props.outlined
      ? "#fff"
      : "linear-gradient(357deg, rgba(35, 124, 233, 1) 0%, rgba(65, 155, 239, 1) 57%)"};
  border: ${(props) => (props.outlined ? "2px solid rgb(35, 124, 233)" : "0")};
  border-radius: 8px;
  margin-right: 25px;
  margin-top: 10px;
  margin-bottom: 10px;
  transition: 0.3s;
  color: ${(props) => (props.outlined ? "rgb(35, 124, 233)" : "#fff")};

  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: bold;

  :hover {
    opacity: 0.9;
    -webkit-box-shadow: -2px 12px 32px -14px rgba(35, 124, 233, 1);
    -moz-box-shadow: -2px 12px 32px -14px rgba(35, 124, 233, 1);
    box-shadow: -2px 15px 32px -14px rgba(35, 124, 233, 10);
  }
`;
