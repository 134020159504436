import React from "react";
import { FiArrowRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import getstartedImg from "../../assets/getstarted.png";

import {
  Container,
  Card,
  CardHeader,
  ImgCongratulation,
  CardTitle,
  CardSubTitle,
  ActionWrapper,
  Button,
} from "./styles";

export default function Welcome() {
  const navigate = useNavigate();

  return (
    <Container>
      <Card>
        <CardHeader>
          <ImgCongratulation src={getstartedImg} alt="CongratulationImg" />
          <CardTitle>Get started!</CardTitle>
          <CardSubTitle>
            Vamos dar início ao seu teste de nivelamento. O teste consiste em teste e evoluir que.
          </CardSubTitle>
        </CardHeader>
        <ActionWrapper>
          <Button onClick={() => navigate("/register")}>
            <FiArrowRight size={35} color="#fff" />
          </Button>
        </ActionWrapper>
      </Card>
    </Container>
  );
}
