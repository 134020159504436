import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useAuth from "../hooks/auth";

import AppRoutes from "./Default";
import AuthRoute from "./Auth";

function Routes() {
  const token = localStorage.getItem("@ai-pt: token");
  const { signed } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      return navigate("/login");
    }

    return navigate("/home");
  }, [signed]);

  return token ? <AppRoutes /> : <AuthRoute />;
}

export default Routes;
