import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";

import api from "../../services/api";

import Header from "../../components/Header";
import TestImage from "../../assets/2808343.jpg";
import TestResultModal from "../../components/Modal/TestResultModal";

import {
  Container,
  Card,
  CardLeft,
  Image,
  TextWrapepr,
  CardTitle,
  CardSubTitle,
  ActionWrapper,
  Button,
} from "./styles";

export default function Home() {
  const [visibleModal, setVisibleModal] = useState(false);
  const [testResult, setTestResult] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    async function loadTestResult() {
      console.log("CHEGOU AQUI", api.headers);
      try {
        const { data } = await api.get("/testResult");
        setTestResult(data);
      } catch (error) {
        console.log(error);
      }
    }

    const token = localStorage.getItem("@ai-pt: token");
    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
    }
    loadTestResult();
  }, []);

  return (
    <Container>
      <Header />

      <Card>
        <CardLeft>
          <Image src={TestImage} />

          <TextWrapepr>
            <CardTitle>Try the test!</CardTitle>
            <CardSubTitle>Pratique o quanto quiser.</CardSubTitle>
          </TextWrapepr>
        </CardLeft>

        <ActionWrapper>
          <Button onClick={() => navigate("/questions")}>PRATICE TEST</Button>
          {testResult && (
            <Button outlined onClick={() => setVisibleModal(true)}>
              MY TEST
            </Button>
          )}
        </ActionWrapper>
      </Card>

      <TestResultModal
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
        testResult={testResult}
      />
    </Container>
  );
}
