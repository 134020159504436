import { Container, ReceiveInput } from "./styles";

export default function Input({ icon: Icon, error, ...rest }) {
  return (
    <Container error={error}>
      {Icon && <Icon size={20} style={{ marginLeft: 8 }} />}
      <ReceiveInput {...rest} />
    </Container>
  );
}
