import styled from "styled-components";

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px;
`;

export const StyledText = styled.h3`
  font-size: 15px;
  font-weight: bold;
`;
