import React, { useState } from "react";
import { Formik } from "formik";
import { object, string, number } from "yup";

import { AiOutlineMail, AiOutlineFieldNumber } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";
import { CiLocationOn } from "react-icons/ci";
import { RiLockPasswordLine } from "react-icons/ri";
import { BiText } from "react-icons/bi";
import { MdOutlineWorkOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import {
  Container,
  Card,
  CardHeader,
  CardTitle,
  CardSubTitle,
  Form,
  ContainerForm,
  DivForm,
  ActionWrapper,
  Button,
  BoxButton,
  ButtonLink,
  BoxCard,
} from "./styles";

import Input from "../../components/Input";
import Header from "../../components/Header";
import api from "../../services/api";

const yupValidation = object({
  fullName: string().required("Por-favor informe um nome."),
  age: number().required("Por-favor informe uma idade."),
  profession: string().required("Por-favor informe uma profissão."),
  phone: number().required("Por-favor informe um celular."),
  zipcode: number().required("Por-favor informe um cep."),
  email: string().email("Email inválido.").required("Por-favor informe um email"),
  password: string().required("Por-favor informe uma senha").min(6),
});

export default function SignUp() {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  async function handleSignUp(data) {
    try {
      setLoading(true);
      await api.post("users", data);
      navigate("/login");
      toast.success("Usuário cadastrado com sucesso!");
      setLoading(false);
    } catch (error) {
      toast.success("Houve um erro ao cadastrar usuário!");
      setLoading(false);
    }
  }

  return (
    <Container>
      <Header />
      <BoxCard>
        <Card>
          <CardHeader>
            <CardTitle>Crie sua conta</CardTitle>
            <CardSubTitle>para acessar gratuitamente o teste de nivelamento.</CardSubTitle>
          </CardHeader>
          <Formik
            initialValues={{
              fullName: "",
              age: "",
              profession: "",
              phone: "",
              zipcode: "",
              email: "",
              password: "",
            }}
            validationSchema={yupValidation}
            onSubmit={(values, { setSubmitting }) => {
              handleSignUp(values);
              setSubmitting(false);
            }}
          >
            {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
              <>
                <ContainerForm>
                  <DivForm>
                    <Input
                      icon={BiText}
                      placeholder="Nome completo"
                      type="text"
                      name="fullName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.fullName}
                      error={errors.fullName}
                    />
                  </DivForm>
                  <Form>
                    <Input
                      icon={AiOutlineFieldNumber}
                      placeholder="Idade"
                      type="number"
                      name="age"
                      min={0}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.age}
                      error={errors.age}
                    />
                    <Input
                      icon={MdOutlineWorkOutline}
                      placeholder="Profissão"
                      type="text"
                      name="profession"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.profession}
                      error={errors.profession}
                    />
                    <Input
                      icon={BsTelephone}
                      placeholder="Celular"
                      type="number"
                      name="phone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      error={errors.phone}
                    />
                    <Input
                      icon={CiLocationOn}
                      placeholder="Cep"
                      type="number"
                      name="zipcode"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.zipcode}
                      error={errors.zipcode}
                    />
                    <Input
                      icon={AiOutlineMail}
                      placeholder="Email"
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      error={errors.email}
                    />
                    <Input
                      icon={RiLockPasswordLine}
                      placeholder="Senha"
                      type="password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      error={errors.password}
                    />
                  </Form>
                </ContainerForm>
                <ActionWrapper>
                  <BoxButton>
                    <Button type="submit" onClick={handleSubmit}>
                      {loading ? "Loading..." : "Cadastrar"}
                    </Button>

                    <ButtonLink onClick={() => navigate("/login")}>Já tenho conta</ButtonLink>
                  </BoxButton>
                </ActionWrapper>
              </>
            )}
          </Formik>
        </Card>
      </BoxCard>
    </Container>
  );
}
