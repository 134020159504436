import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background: #f0f0f0;
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: #ffffff;
  width: 80%;
  max-width: 900px;
  height: 500px;
  -webkit-box-shadow: -1px 3px 15px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 3px 15px -7px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 3px 15px -7px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
  margin-top: 100px;

  @media (max-width: 560px) {
    width: 95%;
    height: 450px;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 125px;
  text-align: center;
  background: rgb(35, 124, 233);
  background: linear-gradient(357deg, rgba(35, 124, 233, 1) 0%, rgba(65, 155, 239, 1) 57%);
  box-shadow: -1px 3px 15px -7px rgba(0, 0, 0, 0.75);
  border-radius: 14px;
  margin-top: -50px;

  @media (max-width: 560px) {
    width: 90%;
  }
`;

export const HeaderTitle = styled.h1`
  margin: 8px 0px 0px;
  font-size: 1.5rem;
  line-height: 1.375;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: 0.00735em;
  opacity: 1;
  color: rgb(255, 255, 255);
  font-weight: 600;
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

export const AnswerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px;
`;

export const AnswerCheckInput = styled.input`
  height: 25px;
  width: 25px;
  border: solid white;
  border-width: 0 3px 3px 0;
  background-color: #eee;
  cursor: pointer;
  margin-right: 20px;

  :hover {
    background-color: #ccc;
  }

  :checked {
    background-color: #2196f3;
  }
`;

export const StyledLabel = styled.label`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: #333;
  padding-right: 100px;
`;

export const Button = styled.button`
  width: 200px;
  height: 50px;
  background: rgb(35, 124, 233);
  background: linear-gradient(357deg, rgba(35, 124, 233, 1) 0%, rgba(65, 155, 239, 1) 57%);
  outline: 0;
  border: 0;
  border-radius: 10px;
  margin-bottom: 25px;
  transition: 0.2s;

  :hover {
    -webkit-box-shadow: -2px 12px 32px -14px rgba(35, 124, 233, 1);
    -moz-box-shadow: -2px 12px 32px -14px rgba(35, 124, 233, 1);
    box-shadow: -2px 12px 32px -14px rgba(35, 124, 233, 1);
  }
`;

export const ButtonText = styled.span`
  font-size: 14px;
  color: #fff;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: bold;
`;
