import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background: #f0f0f0;
`;

export const BoxCard = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffff;
  width: 900px;
  height: 550px;
  -webkit-box-shadow: -1px 3px 15px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 3px 15px -7px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 3px 15px -7px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
  @media (max-width: 720px) {
    width: 100vw;
    height: 100%;
    -webkit-box-shadow: 0;
    -moz-box-shadow: 0;
    box-shadow: 0;
    border-radius: 0;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;

export const CardTitle = styled.h1`
  color: #4c4c4c;
  font-family: din-round, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 25px;
  padding-top: 80px;
  @media (max-width: 720px) {
    padding-top: 40px;
  }
`;

export const CardSubTitle = styled.p`
  color: #4c4c4c;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 18px;
  margin: 15px 0 25px 0;
`;

export const Form = styled.div`
  width: 100%;
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 720px) {
    display: flex;
    flex-direction: column;
  }
`;

export const DivForm = styled.div`
  display: flex;
  padding-bottom: 15px;
  width: 100%;
`;

export const ContainerForm = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 90px;
  @media (max-width: 720px) {
    padding: 0 20px;
  }
`;

export const Button = styled.button`
  width: 200px;
  height: 50px;
  background: rgb(35, 124, 233);
  background: linear-gradient(357deg, rgba(35, 124, 233, 1) 0%, rgba(65, 155, 239, 1) 57%);
  outline: 0;
  border: 0;
  border-radius: 8px;
  transition: 0.4s;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  margin-top: 15px;

  :hover {
    opacity: 0.9;
    -webkit-box-shadow: -2px 12px 32px -14px rgba(35, 124, 233, 1);
    -moz-box-shadow: -2px 12px 32px -14px rgba(35, 124, 233, 1);
    box-shadow: -2px 15px 32px -14px rgba(35, 124, 233, 10);
  }
`;

export const Separator = styled.div`
  width: 70%;
  height: 1px;
  background-color: #cfcfcf;
  margin: 10px;
`;

export const ButtonLink = styled.button`
  width: 200px;
  height: 50px;
  background: transparent;
  outline: 0;
  border: 0;
  font-size: 14px;
`;

export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const BoxButton = styled.div`
  display: flex;
  flex-direction: column;
`;
