import React from "react";
import { useNavigate } from "react-router-dom";
import congratulationImg from "../../assets/46485.png";

import Header from "../../components/Header";

import {
  Container,
  BoxCard,
  Card,
  CardHeader,
  ImgCongratulation,
  CardTitle,
  CardSubTitle,
  ActionWrapper,
  Button,
} from "./styles";

export default function FinishedTest() {
  const navigate = useNavigate();

  return (
    <Container>
      <Header />

      <BoxCard>
        <Card>
          <CardHeader>
            <ImgCongratulation src={congratulationImg} alt="CongratulationImg" />
            <CardTitle>PARABÉNS!</CardTitle>
            <CardSubTitle>
              {`Você terminou seu nivelamento, volte para a página principal e acesse o resultado do
              teste atráves do botão "MY TEST"`}
            </CardSubTitle>
          </CardHeader>
          <ActionWrapper onClick={() => navigate("/home")}>
            <Button>VOLTAR AO INÍCIO</Button>
          </ActionWrapper>
        </Card>
      </BoxCard>
    </Container>
  );
}
