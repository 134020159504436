import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: #f0f0f0;
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: #ffffff;
  width: 900px;
  height: 500px;
  -webkit-box-shadow: -1px 3px 15px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 3px 15px -7px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 3px 15px -7px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
`;

export const ImgCongratulation = styled.img`
  width: 40%;
`;

export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;

export const CardTitle = styled.h1`
  padding-top: 40px;
  color: #4c4c4c;
  font-family: din-round, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 25px;
`;

export const CardSubTitle = styled.p`
  color: #4c4c4c;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 18px;
  margin-top: 10px;
`;

export const Form = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 720px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Button = styled.button`
  width: 50px;
  height: 50px;
  background: rgb(35, 124, 233);
  background: linear-gradient(357deg, rgba(35, 124, 233, 1) 0%, rgba(65, 155, 239, 1) 57%);
  outline: 0;
  border: 0;
  border-radius: 50px;
  margin-right: 10px;
  transition: 0.4s;
  :hover {
    opacity: 0.9;
    -webkit-box-shadow: -2px 12px 32px -14px rgba(35, 124, 233, 1);
    -moz-box-shadow: -2px 12px 32px -14px rgba(35, 124, 233, 1);
    box-shadow: -2px 15px 32px -14px rgba(35, 124, 233, 10);
    margin-bottom: 5px;
  }
`;
