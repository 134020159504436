import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 0 30px;
  width: 100%;
  height: 80px;
  -webkit-box-shadow: 0px 10px 23px -7px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 10px 23px -7px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 23px -7px rgba(0, 0, 0, 0.1);
`;

export const Content = styled.div`
  width: 90%;
  height: 70px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  nav {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 120px;
    }

    a {
      font-weight: bold;
      color: #7159c1;
    }
  }

  aside {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: 0.4s;
  }
`;

export const Profile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Dropdown = styled.div`
  position: absolute;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 120px;
  height: 80px;
  background: #fff;
  margin-top: 120px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.2);
`;

export const DropdownButton = styled.button`
  border: 0;
  outline: none;
  background: #fff;
  font-size: 14px;
  color: #999999;
  font-weight: bold;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva,
    Verdana, sans-serif;
  text-align: left;
  margin-top: 15px;
  margin-left: 15px;
  transition: 0.3s;

  :hover {
    color: #333;
  }
`;

export const ButtonLogin = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 110px;
  padding: 10px;
  gap: 5px;
  border-radius: 10px;
  border-style: solid;
  border-color: rgba(35, 124, 233, 1);
  color: rgba(35, 124, 233, 1);
  background: transparent;
  transition: 0.4s;
  :hover {
    opacity: 0.9;
    -webkit-box-shadow: -2px 12px 32px -14px rgba(35, 124, 233, 1);
    -moz-box-shadow: -2px 12px 32px -14px rgba(35, 124, 233, 1);
    box-shadow: -2px 15px 32px -14px rgba(35, 124, 233, 10);
    background: rgba(35, 124, 233, 1);
    color: #ffffff;
  }

  svg {
    height: 25px;
    width: 25px;
  }
`;
